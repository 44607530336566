const tableCustomStyles = {

    headCells: {
      style: {
        fontSize: '20px',
        fontWeight: 'bold',
        backgroundColor: '#FFA500'
      },
    },

    

    
  }
  export { tableCustomStyles };